import { Modal, Button, Progress } from "@mantine/core";
import {
	createProductWithQueue,
	updateProductWithQueue,
	getProductsChannels,
	getAllProductsChannelsTypesLinks,
} from "../redux/slices/product";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formateMetafield, getMetafields } from "../redux/slices/metafields";
import { useUser } from "src/context/UserContext";
import type { NewTagProperties } from "src/@types/props";
import { checkToken } from "src/auth/utils";

export default function ConfirmModal({
	opened,
	actions,
	rowData,
	setAwaitingProduct,
	handleClosePreview,
	ignoreUpdate,
	ignoreCreate,
	dbData,
}: NewTagProperties) {
	const { user } = useUser();

	const { t } = useTranslation();

	const [importSatus, setImportStatus] = useState<boolean>(false);
	const [passDelayControl, setPassDelayControl] = useState<boolean>(false);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassDelayControl(event.target.checked);
	};

	const handleImportClick = async () => {
		checkToken();
		const { data } = await getMetafields();
		const products = formateMetafield(rowData, data);
		const toCreate = ignoreCreate
			? null
			: products?.filter(
					(product: { Action: string }) =>
						product.Action === t("confirmModal.action.create"),
				);
		const toUpdate = ignoreUpdate
			? null
			: products?.filter(
					(product: { Action: string }) =>
						product.Action === t("confirmModal.action.update"),
				);
		try {
			setImportStatus(true);
			if (toCreate?.length && user) {
				const productChannels = await (async () => {
					const links: {
						id: string;
						publicationExternalId: string;
						publicationTypeId: string;
					}[] = await getAllProductsChannelsTypesLinks();

					const names: {
						publicationId: string;
						publicationName: string;
					}[] = await getProductsChannels();

					const namesMap = names.reduce(
						(acc, { publicationId, publicationName }) => {
							acc[publicationId] = publicationName;
							return acc;
						},
						{} as { [key: string]: string },
					);

					const DEFAULT_PUBLICATIONS = [
						"Online Store",
						"Point of Sale",
						"Inbox",
						"Hydrogen",
						"Headless",
						"Google & YouTube",
					];

					return links.reduce(
						(acc, { publicationExternalId, publicationTypeId }) => {
							const name = namesMap[publicationExternalId] || "Unknown";
							if (DEFAULT_PUBLICATIONS.includes(name)) {
								acc.push(publicationTypeId);
							}
							return acc;
						},
						[] as string[],
					);
				})();
				await createProductWithQueue(
					toCreate,
					user.id,
					productChannels,
					passDelayControl,
				);
			}

			if (toUpdate?.length && user) {
				await updateProductWithQueue(toUpdate, user.id, dbData);
			}

			notifications.show({
				title: "Success",
				message: `${t("confirmModal.notification.success")}`,
				color: "blue",
			});
			setAwaitingProduct(true);
			handleClosePreview();
			actions.close();
		} catch (error) {
			notifications.show({
				title: "Error",
				message: `${t("confirmModal.notification.error")},${error.message}`,
				color: "red",
			});
		} finally {
			setImportStatus(false);
		}
	};

	return (
		<Modal
			zIndex={300}
			opened={opened}
			onClose={actions.close}
			title={t("confirmModal.title")}
			centered
		>
			{user?.role === "ADMIN" && (
				<div>
					<label>
						<input
							type="checkbox"
							checked={passDelayControl}
							onChange={handleCheckboxChange}
						/>
						{t("confirmModal.label")}
					</label>
				</div>
			)}
			<Button
				disabled={importSatus}
				fullWidth
				onClick={() => handleImportClick()}
			>
				{t("confirmModal.import")}
			</Button>
			{importSatus && (
				<Progress style={{ marginTop: 10 }} value={100} animated />
			)}
		</Modal>
	);
}
