// // utils
// import axios from '../utils/axios';

// // ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// // ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// // ----------------------------------------------------------------------

const tokenExpired = (exp: number) => {
  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    localStorage.removeItem('accessToken');
    window.location.href = "https://solution.mdp-distri.com/login";
  }, timeLeft);
};

// // ----------------------------------------------------------------------

export const setSession = (
  siteId: string | null,
  accessToken: string | null,
) => {
  if (accessToken && siteId) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('siteId', siteId);

    const { exp } = jwtDecode(accessToken);

    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('siteId');

  }
};

export function checkToken() {
  const accessToken = localStorage?.getItem('accessToken');
  if (accessToken && !isValidToken(accessToken)) {
    localStorage.removeItem('accessToken');
    window.location.href = "https://solution.mdp-distri.com/login";
  }
}