/* eslint-disable react-hooks/exhaustive-deps */
import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import type { ColDef } from "ag-grid-community";
import "./grid.css";
import * as XLSX from "xlsx";
import {
	ActionIcon,
	Button,
	Flex,
	Image,
	Progress,
	Switch,
	Text,
	TextInput,
	Tooltip,
} from "@mantine/core";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import {
	getProductById,
	getProductsInQueue,
	searchProductsRow,
	updateSingleProductWithQueue,
} from "src/redux/slices/product";
import PreviewImportModal from "./previewImportModal";
import InQueuePopup from "./inQueuePopup";
import { notifications } from "@mantine/notifications";
import { ShowSheetSelection } from "./showSheetSelection";
import Lang from "./lang";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useUser } from "src/context/UserContext";
import logo from "../img/MDP_Icon_200x200px.png";
// import type { inProgress } from "src/@types/props";
export default function TableData() {
	const { user } = useUser();
	const { t } = useTranslation();
	const gridRef = useRef<AgGridReact>(null);
	const [colDefs, setColDefs] = useState<ColDef[]>([]);
	const [previewImportModalOpened, previewImportModalActions] =
		useDisclosure(false);
	const [jsonData, setJsonData] = useState<string[][]>([]);
	const rowSelection = "multiple";
	const paginationPageSizeSelector = [50, 100];
	const paginationPageSize = 100;
	const [loadingValue, setLoadingValue] = useState<boolean>(false);
	const [productsInQueue, setProductsInQueue] = useState<number>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [, setCurrentPage] = useState(1);
	const [nameFilter, setFilterName] = useState("");
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const [query, setQuery] = useState<any>();
	const [intervalTime, setIntervalTime] = useState(20000);
	const [showNoResult, setShowNoResult] = useState<boolean>(false);
	const [showSheetOpened, showSheetActions] = useDisclosure(false);
	const [workbook, setWorkbook] = useState<XLSX.WorkBook>();
	const [sheetName, setSheetName] = useState<string[]>([]);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [awaitingProduct, setAwaitingProduct] = useState(false);
	const [rowHeight, setRowHeight] = useState(42);
	const [isCompactView, setIsCompactView] = useLocalStorage<boolean>({
		key: "compactViewHome",
		defaultValue: false,
	});

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const handleSwitchCompactView = (event: any) => {
		setIsCompactView(event.currentTarget.checked);
		setRowHeight(event.currentTarget.checked ? 32 : 42);
	};

	const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterName(event.target.value);
	};

	const onSearch = async () => {
		const searchQuery = {
			...(nameFilter.toLowerCase().length > 0
				? {
						_and: [
							{
								_or: [
									{
										title: {
											_ilike: `%${nameFilter.toLowerCase()}%`,
										},
									},
									{
										variants: {
											barcode: {
												_eq: nameFilter.toLowerCase(),
											},
										},
									},
								],
							},
						],
					}
				: {}),
		};

		if (nameFilter.toLowerCase().length === 0 && searchQuery._and?.length) {
			// biome-ignore lint/performance/noDelete: <explanation>
			delete searchQuery._and;
		}

		setQuery(searchQuery);
	};

	const reloadDataSource = () => {
		if (gridRef.current) {
			gridRef.current.api?.setGridOption("datasource", infiniteDataSearch);
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		reloadDataSource();
	}, [query]);

	const fetchProductsInQueue = async () => {
		
		const response = await getProductsInQueue();
		setProductsInQueue(response);
		if (productsInQueue && awaitingProduct) {
			setAwaitingProduct(false);
		}
			setIntervalTime(20000);
		
	};
	const doNotEdit = [
		// "Handle",
		"Variant Barcode",
		"Variant SKU",
		"meta_reference_interne",
		"Unité d'intégration",
		"Variant Reseller Price",
		"Template suffix",
	];
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleFileChange = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			setIsModalOpen(true);
			const selectedFile = e.target.files?.[0];

			if (selectedFile) {
				try {
					const data = await selectedFile.arrayBuffer();
					const workbook = XLSX.read(data);
					if (!workbook.SheetNames.length) {
						throw new Error("No sheets found in the file");
					}
					setWorkbook(workbook);
					const sheetNames = workbook.SheetNames;
					setSheetName(sheetNames);
					if (sheetNames.length > 1) {
						showSheetActions.open();
					} else {
						const worksheet = workbook.Sheets[sheetNames[0]];
						const jsonData = XLSX.utils.sheet_to_json<string[][]>(worksheet, {
							header: 1,
							defval: "",
						}).map(row => row.map(cell => cell !== undefined ? String(cell) : ""));
						setJsonData(jsonData);
						previewImportModalActions.open();
						setIsModalOpen(true);
					}
				} catch (error) {
					console.error("Error reading file:", error);
					notifications.show({
						title: t("tableData.notification.title"),
						message: t("tableData.notification.message"),
						color: "red",
					});
				} finally {
					if (fileInputRef.current) {
						fileInputRef.current.value = "";
					}
				}
			}
		},
		[previewImportModalActions, showSheetActions],
	);

	const handleSheetSelect = (selectedSheet: string) => {
		if (!workbook) {
			throw new Error("Workbook is not set");
		}
		const worksheet = workbook.Sheets[selectedSheet];
		setJsonData(
			XLSX.utils.sheet_to_json(worksheet, {
				header: 1,
				defval: "",
			}),
		);
		previewImportModalActions.open();
	};

	const handleModalClose = useCallback(() => {
		previewImportModalActions.close();
		setIsModalOpen(false);
	}, [previewImportModalActions]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!isModalOpen) {
			const intervalId = setInterval(fetchProductsInQueue, intervalTime);
			return () => clearInterval(intervalId);
		}
	}, [intervalTime, isModalOpen]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!isModalOpen) {
			fetchProductsInQueue();
			return;
		}
	}, []);

	const infiniteDataSearch = {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		getRows: async (params: any) => {
			const { startRow } = params;
			const page = Math.floor(startRow / 100) + 1;
			try {
				setLoadingValue(true);

				const { data: products, pagination } = await searchProductsRow(
					query,
					100,
					page,
				);

				if (!products.length) {
					setShowNoResult(true);
					setLoadingValue(false);
				} else {
					setShowNoResult(false);
				}

				const productPromises = products.map((product) =>
					getProductById(product["external id"] || product.id),
				);

				const productsFromDatabase = await Promise.all(productPromises);

				if (page === 1) {
					const column = Object.keys(productsFromDatabase.reduce((acc, curr) => {
						for (const key of Object.keys(curr)) {
							if (!acc[key]) {
								acc[key] = "";
							}
						}
						return acc;
					}, {}))
						.filter((key) => key.charAt(0) === key.charAt(0).toUpperCase() || key.slice(0, 5) === "meta_")
						.map((key) => ({
							headerName: t(`knownColumns.${key}`),
							field: key,
							singleClickEdit: true,
							editable: !doNotEdit.includes(key),
							sortable: false,
							// biome-ignore lint/suspicious/noExplicitAny: <explanation>
							cellStyle: (params: any) => {
								const alternateBackgroundColor =
									params.rowIndex % 2 === 0 ? "transparent" : "#0000000A";
								return { backgroundColor: alternateBackgroundColor };
							},
							// biome-ignore lint/suspicious/noExplicitAny: <explanation>
							onCellClicked(params:any) {
								if (doNotEdit.includes(key)) {
									if (document.hasFocus()) {
										navigator.clipboard.writeText(params.value);
					
										notifications.show({
											title: key,
											message: `${params.value}${t("previewImportModal.notification.message")}`,
											color: "blue",
										});
									}
								}
							},
							cellClass: () => {
								if (doNotEdit.includes(key)) {
									return "pointer-cursor";
								}
								return "";
							},
						}));
					setColDefs(column);
				}

				params.successCallback(productsFromDatabase, pagination.total);

				setLoadingValue(false);
			} catch (error) {
				console.error("Error fetching products:", error);
				params.failCallback();
			}
		},
	};
	

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const onGridReady = (params: any) => {
		params.api.setGridOption("datasource", infiniteDataSearch);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			onSearch();
		}
	};

	const handleRedirectMDP = () => {
		window.location.href = "https://solution.mdp-distri.com/";
	};

	return (
		<>
			<div>
				<div
					className="ag-theme-quartz"
					style={{ height: "93vh", width: "100%" }}
				>
					<Flex justify={"space-between"} p={8}>
						<Flex gap={14} align="center">
							<Flex
								gap={10}
								align="center"
								style={{ cursor: "pointer" }}
								onClick={handleRedirectMDP}
							>
								<Image w="auto" h="56px" src={logo} />
								{/* <Text c="black" size="lg" fw={700}>
									MDP Bulk
								</Text> */}
							</Flex>

							<Button component="label" htmlFor="file-input">
								{t("tableData.import")}
								<input
									id="file-input"
									type="file"
									accept=".xls,.xlsx,.csv"
									onChange={handleFileChange}
									style={{ display: "none" }}
									ref={fileInputRef}
								/>
							</Button>
						</Flex>
						<ShowSheetSelection
							opened={showSheetOpened}
							actions={showSheetActions}
							fileName={Array.isArray(sheetName) ? sheetName : [sheetName]}
							onSheetSelect={handleSheetSelect}
						/>
						<Flex gap={40} align={"center"}>
							<Switch
								labelPosition="left"
								label={t("tableData.compact")}
								checked={isCompactView}
								onChange={handleSwitchCompactView}
							/>
							<Flex align={"center"}>
								<TextInput
									type="text"
									id="filter-text-box"
									placeholder={t("tableData.searchPlaceholder")}
									onChange={onFilterChange}
									onKeyDown={handleKeyDown}
									rightSection={
										<ActionIcon
											size="lg"
											disabled={loadingValue}
											onClick={() => onSearch()}
											variant="transparent"
										>
											<Icon
												icon="mingcute:search-line"
												width="20px"
												height="20px"
											/>
										</ActionIcon>
									}
								/>

								<Lang />
								<Tooltip label={t("helpTooltip")}>
									<Icon
										width={28}
										height={28}
										icon="solar:question-circle-bold"
										onClick={() =>
											window.open(
												"https://cdn.shopify.com/s/files/1/0793/6776/6332/files/Documentation_MDP_Bulk.pdf?v=1723657035",
												"_blank",
											)
										}
										style={{ cursor: "pointer" }}
									/>
								</Tooltip>
							</Flex>
						</Flex>
					</Flex>
					{showNoResult && (
						<Text
							style={{
								position: "absolute",
								top: "50%",
								left: " 50%",
								transform: "translate(-50%, -50%)",
								textAlign: "center",
								userSelect: "none",
								zIndex: 100,
							}}
						>
							{t("tableData.noProduct")}
						</Text>
					)}

					<AgGridReact
						suppressNoRowsOverlay
						ref={gridRef}
						columnDefs={colDefs}
						rowModelType="infinite"
						rowSelection={rowSelection}
						rowHeight={rowHeight}
						pagination={true}
						cacheBlockSize={100}
						maxBlocksInCache={10}
						paginationPageSize={paginationPageSize}
						paginationPageSizeSelector={paginationPageSizeSelector}
						animateRows={false}
						onPaginationChanged={() => {
							const currentPage =
								gridRef.current?.api?.paginationGetCurrentPage();
							if (currentPage !== undefined) {
								setCurrentPage(currentPage + 1);
							}
						}}
						onCellEditingStopped={(params) => {
							if (user) {
								updateSingleProductWithQueue(params.data, user.id);
							}
						}}
						onGridReady={onGridReady}
					/>
				</div>
			</div>
			{loadingValue && <Progress value={100} animated />}
			<PreviewImportModal
				opened={previewImportModalOpened}
				actions={previewImportModalActions}
				jsonData={jsonData}
				onClose={handleModalClose}
				setJsonData={setJsonData}
				setAwaitingProduct={setAwaitingProduct}
			/>
			{(productsInQueue || awaitingProduct) && (
				<InQueuePopup
					productsInQueue={productsInQueue || 0}
					awaitingProduct={awaitingProduct}
				/>
			)}
		</>
	);
}
