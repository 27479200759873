import { useState, useEffect } from "react";
import {
	Modal,
	Button,
	Text,
	Flex,
	Title,
	Box,
	NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useUser } from "src/context/UserContext";
import type { ColumnMappingModalProps, Mapping } from "src/@types/props";
import { checkToken } from "src/auth/utils";

export function ColumnMappingModal({
	opened,
	onClose,
	columns,
	onConfirm,
	availableName,
	mappingSave,
	setMappingSave,
	onStopImport,
}: ColumnMappingModalProps): JSX.Element {
	// const [mappings, setMappings] = useState<{ [key: string]: string }>({});
	const { t } = useTranslation();

	const { isAdmin } = useUser();

	const [mappingSaveLocal, setMappingSaveLocal] = useLocalStorage<Mapping>({
		key: "mappingSaveLocal",
		defaultValue: {},
	});

	const matchingTable = {
		Description: [
			"body",
			"body html",
			"resume",
			"body_html",
			"body-html",
			"body.html",
		],
		"Variant Price": [
			"price",
			"prix",
			"prix variante",
			"variant_price",
			"variant-price",
			"variant.price",
		],
		"Product Type": [
			"type",
			"product type",
			"type de produit",
			"product_type",
			"product-type",
			"product.type",
		],
		Title: [
			"titre",
			"title_name",
			"title-name",
			"title.name",
			"product title",
			"product_title",
			"product-title",
			"product.title",
		],
		"Variant Barcode": [
			"barcode",
			"barcodes",
			"barecode",
			"code barre",
			"code-barres",
			"variant_barcode",
			"variant-barcode",
			"variant.barcode",
			"gencode",
			"gencod",
			"ean",
		],
		"Variant Weight": [
			"poids",
			"masse",
			"grams",
			"variant weight",
			"weight",
			"variant_grams",
			"variant-grams",
		],
		"Variant Inventory Policy": [
			"inventory policy",
			"policy",
			"inventory_policy",
			"variant_inventory_policy",
			"variant-inventory-policy",
			"inventory-policy",
		],
		"Variant Inventory Quantity": [
			"inventory quantity",
			"quantity",
			"inventory_quantity",
			"inventory-quantity",
			"variant_inventory_quantity",
			"variant-inventory-quantity",
			"disponnible",
		],
		"Variant SKU": [
			"sku",
			"variant sku",
			"reference",
			"ref",
			"variant_sku",
			"variant-sku",
		],
		Vendor: [
			"fournisseur",
			"vendeur",
			"provider",
			"vendeur",
			"vendor_name",
			"vendor-name",
			"editeur",
		],
	};

	const mappingForm = useForm<Mapping>({
		validate: (values: Record<string, string>) => {
			const errors: Record<string, string> = {};

			for (const key of Object.keys(values)) {
				const value = values[key].trim();

				if (
					value !== "" &&
					value !== "- - - - - > - skip - > - - - - - -" &&
					!availableName.includes(value)
				) {
					errors[key] = t("columnMapping.error");
				}
			}

			return errors;
		},
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (opened) {
			if (mappingSave) {
				mappingForm.setValues(mappingSave);
			} else {
				const initialMappings = columns.reduce(
					(acc, col) => {
						const lowerCol = col.toLowerCase();

						const foundName = availableName.find(
							(name) => name.toLowerCase() === lowerCol,
						);

						acc[col] = foundName ? foundName : "";
						return acc;
					},
					{} as { [key: string]: string },
				);

				mappingForm.setValues(initialMappings);

				for (const [standardName, synonyms] of Object.entries(matchingTable)) {
					for (const col of columns) {
						if (
							synonyms.map((s) => s.toLowerCase()).includes(col.toLowerCase())
						) {
							initialMappings[col] = standardName;
						}
					}
				}

				mappingForm.setValues(initialMappings);

				if (Object.keys(mappingSaveLocal).length > 0) {
					const filteredMappingSaveLocal = Object.keys(mappingSaveLocal).reduce(
						(acc, key) => {
							if (key in initialMappings) {
								acc[key] = mappingSaveLocal[key];
							}
							return acc;
						},
						{} as Mapping,
					);

					mappingForm.setValues(filteredMappingSaveLocal);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [opened]);

	const requiredFields = [
		// "Title",
		// "Description",
		// "Vendor",
		"Variant Barcode",
		// "Variant SKU",
		// "Variant Price",
	];
	const [showGlobalError, setShowGlobalError] = useState<{
		[key: string]: boolean;
	}>({});

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		checkToken();
		event.preventDefault();

		mappingForm.onSubmit((values: { [key: string]: string }) => {
			const missingFields: { [key: string]: boolean } = {};
			for (const field of requiredFields) {
				missingFields[field] = !Object.values(values).includes(field.trim());
			}

			setShowGlobalError(missingFields);

			if (Object.values(missingFields).some((value) => value)) {
				return;
			}

			setShowGlobalError({});

			const finalMappings = {} as { [key: string]: string };
			const finalMappingsStorage = {} as { [key: string]: string };

			for (const col of Object.keys(values)) {
				const trimmedValue = values[col].trim();
				if (trimmedValue !== "") {
					finalMappingsStorage[col] = trimmedValue;
				}
				if (
					trimmedValue !== "" &&
					trimmedValue !== "- - - - - > - skip - > - - - - - -"
				) {
					finalMappings[col] = trimmedValue;
				}
			}

			setMappingSave(finalMappingsStorage);
			setMappingSaveLocal(finalMappingsStorage);
			mappingForm.reset();
			onConfirm(finalMappings);
			onClose();
		})();
	};

	const removedMetafield = [
		"Template Suffix",
		"Variant SKU",
		"meta_dateparution",
		"meta_realisateur",
		"meta_code_clil",
		"meta_code_distributeur",
		"meta_code_editeur",
		"meta_codesupport",
		"meta_collection_2",
		"meta_garantiemois",
		"meta_icone_produit_jeu_video",
		"meta_icone_produit_musique",
		"meta_dureelicence",
		"meta_editeur",
		"meta_product_type_dvd",
		"meta_product_type_paper",
		"meta_id_lectorat",
		"meta_id_produit",
		"meta_idserie",
		"meta_langueflag",
		"meta_langue",
		"meta_langueiso",
		"meta_libellepublic",
		"meta_nblicenses",
		"meta_nombre_de_disques",
		"meta_precommande",
		"meta_racine_editeur",
		"meta_restriction_age",
		"meta_content_oeuvre_article_collection",
		"meta_content_oeuvre_article_interprete",
	];

	const alwaysRemovedMetafields = [
		"meta_langue",
		"meta_dateparution",
		"meta_editeur",
		"meta_nombre_de_disc_et_page",
		"meta_taxe",
		"meta_age",
		"meta_ann_e_de_sortie",
	];

	let sortedAvailableNames: string[];

	if (isAdmin) {
		sortedAvailableNames = [
			"- - - - - > - skip - > - - - - - -",
			...availableName
				.filter((name) => !alwaysRemovedMetafields.includes(name))
				.sort((a, b) =>
					t(`knownColumns.${a}`, { defaultValue: a }).localeCompare(
						t(`knownColumns.${b}`, { defaultValue: b }),
					),
				),
		];
	} else {
		sortedAvailableNames = [
			"- - - - - > - skip - > - - - - - -",
			...availableName
				.filter(
					(name) =>
						!alwaysRemovedMetafields.includes(name) &&
						!removedMetafield.includes(name),
				)
				.sort((a, b) =>
					t(`knownColumns.${a}`, { defaultValue: a }).localeCompare(
						t(`knownColumns.${b}`, { defaultValue: b }),
					),
				),
		];
	}

	const options = sortedAvailableNames.map((name) => ({
		value: name,
		label: t(`knownColumns.${name}`, { defaultValue: name }),
	}));

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			title={t("columnMapping.title")}
			size="lg"
			style={{ zIndex: "300", position: "relative" }}
			closeOnClickOutside={false}
			closeOnEscape={false}
			withCloseButton={false}
		>
			<form onSubmit={handleSubmit}>
				<Flex
					align="center"
					justify="space-between"
					gap={2}
					style={{ marginBottom: "10px" }}
				>
					<Title order={5}>{t("columnMapping.myColumn")}</Title>
					<Title order={5}>{t("columnMapping.correspond")}</Title>
				</Flex>

				{columns.map((col) => (
					<div key={col}>
						<Flex align="center" justify="space-between" gap={10}>
							<Text w={"50%"} lineClamp={1}>
								{col}
							</Text>
							{/* <TextInput
								w={"50%"}
								size="md"
								{...mappingForm.getInputProps(col)}
								list="availableNames"
							/> */}
							<NativeSelect
								w={"50%"}
								size="md"
								data={options}
								{...mappingForm.getInputProps(col)}
							/>
						</Flex>
					</div>
				))}

				<div
					style={{
						backgroundColor: "white",
						position: "sticky",
						bottom: "0",
						padding: "14px 0px",
						zIndex: "1",
					}}
				>
					<Flex gap={10} align={"center"} justify={"space-between"}>
						<Button onClick={onStopImport} mt="md" color="red">
							{t("columnMapping.cancelImport")}
						</Button>
						{Object.keys(showGlobalError).length > 0 ? (
							<Box mt="md">
								<Text color="red">
									{t("columnMapping.missingField")}
									<br />
									{Object.keys(showGlobalError)
										.filter((field) => showGlobalError[field])
										.join(", ")}
								</Text>
							</Box>
						) : (
							<></>
						)}
						<Button type="submit" mt="md">
							{t("columnMapping.confim")}
						</Button>
					</Flex>
				</div>
			</form>
		</Modal>
	);
}